export const GET_ABOUT = '/api/about/:lang'
export const GET_NEWS = '/api/news/:lang'
export const GET_MEMBERS = '/api/members'
export const GET_PHOTOS = '/api/photos'
export const GET_VIDEOS = '/api/video'
export const GET_MUSIC = '/api/music'
export const GET_DOCS = '/api/docs/:lang'
export const POST_EMAIL = '/api/feedback'
export const GET_SETTINGS = '/api/settings'
export const GET_DISCOGRAPHY = '/api/discography/:lang'
