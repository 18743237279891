import React from "react";
import {useTranslation} from "react-i18next";

const Maintenance = () => {
	const {t} = useTranslation()

	return (
		<div className="page maintenance">
			<div className="logo" />
			<h2 className="maintenance-text">
				{t('Сайт находится на обслуживании. Зайдите позже')}
			</h2>
		</div>
	)
}

export default Maintenance
